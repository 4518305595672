import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/App.vue';
import AboutMe from '@/links.vue'; // 确保路径正确

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/links',
    name: 'links',
    component: AboutMe
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
